// NOTE: Button to be a variant of the button component?
import React from 'react';
import { ArrowForward } from '@material-ui/icons';
import { Container, Grid, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import isNull from 'lodash/isNull';

import { styleConfig } from '../../config/config';

import FaqList from './FaqList';
import RouteLink from './RouteLink';
import Section from './Common/Section';
import Title from './Common/Title';

const ContentModuleFaq: React.FunctionComponent<ContentModuleFaqProps> = (props) => {
  const { subtitle, headline, faqlist, hasButton, dark } = props;

  const useStyles = makeStyles(() => ({
    title: {
      display: 'block',
      marginBottom: '6rem',
    },
    button: {
      transition: 'all .3s ease-in-out',
      display: 'inline-block',
      margin: '2rem 0',
      color: dark ? styleConfig.color.text.white : styleConfig.color.text.darkgrey,
      textDecoration: 'none',
      fontWeight: 600,
      fontSize: 14,

      '& span': {
        display: 'inline-block',
        verticalAlign: 'middle',
        color: styleConfig.color.bg.gold,

        '& svg': {
          width: 20,
          height: 20,
        },
      },

      '&:hover': {
        color: styleConfig.color.bg.gold,
      },
    },
  }));

  const classes = useStyles();

  return (
    faqlist && (
      <Section bg={dark ? styleConfig.color.bg.darkgrey : styleConfig.color.bg.white}>
        <Container maxWidth="lg">
          {!isNull(headline) && !isNull(subtitle) && headline && (
            <Box className={classes.title}>
              <Title
                component="h2"
                alignment="center"
                isSubtitle
                subtitleText={subtitle}
                subtitleType="subtitle1"
                titleCaps
                titleColor={dark ? styleConfig.color.text.white : styleConfig.color.text.darkgrey}
                titleHeavy
                titleSize="h3"
                titleText={headline}
              />
            </Box>
          )}

          <FaqList dark={dark} list={faqlist} />

          {hasButton && (
            <Grid container justifyContent="flex-end">
              <RouteLink href="/contact-us/" className={classes.button}>
                Ask a question{' '}
                <span>
                  <ArrowForward />
                </span>
              </RouteLink>
            </Grid>
          )}
        </Container>
      </Section>
    )
  );
};

interface ContentModuleFaqProps {
  dark?: boolean;
  subtitle: string;
  headline: string;
  faqlist: {
    title_question: string;
    content_answer: string;
  }[];
  hasButton?: boolean;
}

export default ContentModuleFaq;
